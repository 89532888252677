<template>
  <v-container class="custom-container fill-height justify-center">
    <v-card>
      <v-card-title>Página no encontrada</v-card-title>
      <v-card-text>La dirección solictada no es parte de nuestro portal Web, probablemente esté mal escrita o el link al que intenta acceder ya no está disponible.</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "PaginaNoEncontradaView"
}
</script>

<style scoped>

</style>